.payment-requested-header {
    align-self: start;
    margin: 25px;
}

.payment-requested-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.payment-requested-body-upper {
    border-width: 0;
    border-image: linear-gradient(200.95deg, #61E1A4 0.2%, #2DCCED 100%);
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-image-slice: 1;
    background-color: #FFFFFF;
}

.payment-requested-body-lower {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-evenly;
}

.payment-requested-header-logo {
    width: 40px;
    height: 40px;
}

.payment-requested-header-logo-text {
    width: 103px;
}

.payment-requested-photo-container {
    width: 88px;
    height: 88px;
    margin: 25px;
}

.payment-requested-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #0A1F25;
    margin: 20px;
    text-align: left;
}
.payment-requested-status-spinner {
    @extend .spinner;
    margin: 5px
}
.payment-requested-status-icon {
    margin: 7px
}

.payment-requested-amount {
    font-weight: 900;
    font-size: 48px;
    line-height: 60px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #000000;
    margin: 0 20px;
}

.payment-requested-message {
    font-size: 20px;
    line-height: 28px;
    color: #0A1F25;
    display: flex;
    align-items: center;
    margin: 0 20px 20px 20px;
}