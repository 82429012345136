.desktop-page {
  background-image: url(/desktop-background.svg), linear-gradient(166.3deg, #FCFEFF 34.4%, #EFF1F2 72.71%);
  background-repeat: no-repeat;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
}

.desktop-left {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin: 50px 0 0 100px;
}

.desktop-right {
  min-height: 100%;
  margin: 0px 50px;
}

.desktop-bullets {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  list-style:     none;
  padding:        0 1em;
}

.desktop-bullet {
  margin: 35px 0;
  font-family: Source Sans Pro;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
}

li.desktop-bullet:before {
  content: "";
  border-color: transparent #656B6F;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  height: 0;
  width: 0;
  left: -1em;
  top: 0.4em;
  position: relative;
}
.desktop-qr-code-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 178px;
  width: 546px;
  padding: 25px;
  background: #FFFFFF;
  border: 4px solid #65D49F;
  box-sizing: border-box;
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.desktop-qr-code {
  width: 125px;
  height: 125px;
}

.desktop-qr-code-container-text {
  font-family: Source Sans Pro;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 0 35px;
}
