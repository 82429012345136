.sign-in-options {
  display: flex;
  width: 100%;
  align-self: center;
}
.sign-in-button {
  position: absolute;
  z-index: 1000;
  top: 16px;
  right: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: black;
  align-self: flex-end;
  outline: none;
  border: 0;
  background-color: transparent;
}