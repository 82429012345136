.profile-page-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background: linear-gradient(171.17deg, #FCFEFF 34.4%, #EFF1F2 72.71%);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  min-height: 100%;
}

.profile-card {
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  padding: 24px;
  padding-bottom: 0px;
  position: relative;
  z-index: 1;
}

.profile-card-main {
  display: flex;
  flex-direction: row;
}

.profile-card-tabs {
  display: flex;
  margin-top: 10px;
}

.profile-card-tab-button {
  flex: 1;
  border: 0px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-image-slice: 1;
  border-color: transparent;

  &-active {
    @extend .profile-card-tab-button;
    opacity: 1;
    border-color: #43BDF1;
    box-shadow: 0px 17px 20px -10px rgba(67, 189, 241, 0.5);
  }
}

.profile-card-tab-button-icon {
  margin: 4px;
}

.profile-card-tab-button-text {
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
}

.profile-page-primary-button {
  @extend .primary-button;
  z-index: 1;
  position: fixed;
  bottom: 0px;
}

.profile-page-tab-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  object-fit: none;
  height: 50%;
  margin: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
}

.profile-card-main-button-spacer {
  height: 50px;
}

.profile-home-button {
  background: transparent;
  border-width: 0;
  height: 33px;
}

.profile-img-container {
  display: flex;
  width: 20vw;
  height: 20vw;
  align-items: center;
}

// TODO: make this work for long names
.profile-info-container {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  justify-content: center;
  flex: 1;
}

.profile-img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-name {
  font-weight: 600;
  font-size: 6vw;;
  text-align: left;
}

.profile-share-primary {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;  
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #FDFDFD;
  background: #42B398;
  border-radius: 1px;
  padding: 0px 9px;
  width: fit-content;
}

.profile-share-secondary {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #727878;
  background: #FFFFFF;
  padding: 0px 9px;
  width: fit-content;
  border: 1px solid #7A8182;
  box-sizing: border-box;
  border-radius: 1px;
}

.profile-share-icon {
  height: 12px;
  width: 12px;
  margin: 0 8px 0 0;
}

.profile-payment-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 12.5px;
}

.payment-page-body {
  transform: translateY(-40.5px);
  position: relative;
  z-index: 2;
}

.profile-info-bank-logo {
  height: 20px;
  margin: 0 7px 3px;
}

.payment-page-dotted-line-send {
  position: fixed;
  height: 80px;
  width: 0px;
  left: 40px;
  border: 2px dashed #8E9091;
}

.payment-page-dotted-line-request {
  position: fixed;
  height: 180px;
  width: 0px;
  right: 40px;
  border: 2px dashed #8E9091;
}

.payment-page-send-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  width: 190px;
  position: fixed;
  text-align: left;
  left: 22px;
  color: #8E9091;
  transform: translateY(80px);
}

.payment-page-request-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  width: 170px;
  position: fixed;
  right: 22px;
  text-align: right;
  color: #8E9091;
  transform: translateY(180px);
}

.payment-page-how-does-this-work-button {
  position: absolute;
  bottom: 0px;
}

.confirm-page-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confirm-page-bank-provider-icon {
  height: 20px;
  margin: 5px 0 10px 0;
}