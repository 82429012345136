.bm-burger-button {
  position: absolute !important;
  z-index: 1000;
  width: 22px;
  height: 15px;
  right: 23px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #333333;
  height: 2px !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #43BDF1;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  right: 15px !important;
  top: 23px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #333333;
  width: 2px !important;
  height: 18px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  width: 280px !important;
}

/* General sidebar styles */
.bm-menu {
  background: #FFFFFF;
  padding: 0 !important;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 15px 15px 30px !important;
}

/* Individual item */
.bm-item {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #010C0D !important;
  display: block;
  text-align: left;
  padding: 0 !important;
  margin-top: 24px !important;
  margin-left: 5px;
  outline: none;
}

.bm-item-icon {
  width: 24px;
  height: 24px;
  margin-left: 0;
  margin-right: 17px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-header {
  font-size: 24px;
  line-height: 32px;
}

.bm-header-icon {
  width: 40px;
  height: 40px;
  margin-left: 0;
  margin-right: 8px;
}

.bm-sign-out {
  position: absolute;
  bottom: 30px;
  color: #F2545B !important;
}
