.request-page-header {
    align-self: start;
    margin: 35px 20px;
    color: #010C0D;
    letter-spacing: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.request-page-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.request-page-header-logo {
    width: 40px;
    height: 40px;
}

.request-page-header-logo-text {
    width: 103px;
}

.requester-photo-container {
    width: 120px;
    height: 120px;
    margin: 25px;
}

.request-amount {
    margin: 8px;
    font-weight: 900;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #333333;
}

.request-message {
    margin: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;    
    text-align: center;
    color: #000000;
}

.request-page-button-logo {
    width: 40px;
    height: 40px;
    margin: 5px;
}