//TODO: reorganize per https://sass-guidelin.es/#the-7-1-pattern
@import "~bootstrap/scss/bootstrap";

@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;900&display=swap);

@import 'burger-menu';
@import 'page';
@import 'enter-amount';
@import 'landing';
@import 'sign-up';
@import 'sign-in';
@import 'payment-history';
@import 'payment-requests';
@import 'payment-page';
@import 'request-page';
@import 'payment-requested';
@import 'help';
@import 'desktop';

html {
  height: 100%;
  width: 100%;
  margin-top: 0px;
  padding-top: 0px;
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  padding-top: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  border: 0;
  background: transparent;
  outline: none;
}

button:focus {
  outline: 0;
}

input {
  border: 0;
  background: transparent;
  outline: none;
  font-weight: inherit;
  letter-spacing: inherit;
  color: inherit;
}

input:focus {
  outline: 0;
}

#root {
  height: 100%;
  width: 100%;
  margin-top: 0px;
  padding-top: 0px;
}

.app-background {
  height: 100%;
  width: 100%;
  margin-top: 0px;
  padding-top: 0px;
 }

 .sign-in-div {
   padding-top: 330px;
 }

 .landing-title {
   padding-top: 70px;
  //  font-weight: 800;
  //  font-size: 5em;
   color: #ffffff;

   h1 {
    font-size: 5em;
    font-weight: 600;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
   }
 }

 .second-lottie-animation {
   padding-top: 165px;
 }

 .landing-page-middle {
   display: flex;
   flex-direction: row;
   padding-top: 80px;
   justify-content: center;
   width: 100%;
 }

 .landing-page-subtitle {
   color: #ededed;
   font-weight: 400;
  //  font-size: 5em;
 }

 .landing-page-subtitle-container {
   width: 200px;
   text-align: center;
 }

 .sign-in-div {
   position: absolute;
   top: 10%;
  //  width: 400px;
 }


//  firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button
.firebaseui-card-content {
  padding: 0 0 !important;
}

.firebaseui-idp-button {
  height: 56px !important; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important;
  border-width: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
}
 
.firebaseui-idp-icon {
  height: 32px !important;
  width: 32px !important;
}
 
.firebaseui-idp-text {
  letter-spacing: 0.04em !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: 'Source Sans Pro' !important;
  padding-left: 20px !important;
}

.firebaseui-container {
  letter-spacing: 0.04em !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: 'Source Sans Pro' !important;
  background-color: transparent !important;
}

.firebaseui-title {
  text-align: center !important;
}

.firebaseui-button {
  flex-grow: 1;
  font-family: 'Source Sans Pro' !important;
  font-weight: 600 !important;
  height: 54px !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.04em !important;
}
.firebaseui-form-actions {
  text-align: center !important;
  display: flex !important;
}

.firebaseui-card-actions {
  padding: 0 !important;
}

.firebaseui-id-submit {
  background: #43BDF1 !important;
  box-shadow: 0px 8px 12px rgba(67, 189, 241, 0.5) !important;
}

.firebaseui-id-secondary-link {
  color: black !important;
}

.mdl-button {
  text-transform: none !important;
}
