.payment-history-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.payment-history-item {
  display: flex;
  flex-direction: row;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.payment-history-photo {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 16px;
}

.payment-history-users-and-reference {
  flex-grow: 1;
  text-align: left;
}

.payment-history-users {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #010C0D;
}

.payment-history-reference {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #000000;
  opacity: 0.5;
}

.payment-history-amount-and-date {
  flex-grow: 0;
  text-align: right;
}

.payment-history-amount {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.payment-history-amount-positive {
  color: #42B398;
}

.payment-history-amount-negative {
  color: #F2545B;
}

.payment-history-date {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #000000;
  opacity: 0.5;
}