.page-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(171.17deg, #FCFEFF 34.4%, #EFF1F2 72.71%);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    min-height: 100%;
}

.page-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: space-between;
    align-items: center;
    height: 34px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 16px;
}

.header-back-button-container {
    display: flex;
    flex: 1;
}

.header-button {
    background: transparent;
    border-width: 0;
    height: inherit;
}

.header-spacer {
    flex: 1;
}

.header-logo {
    height: 100%;
}

.page-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 30px;
    flex: 1;
}

.input-field {
    background: transparent;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    height: 56px;
    border-width: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120.7%;
    padding: 16px;
    flex-grow: 1;

    &.choose-url-page {
        margin-bottom: 20px;
    }
    
}

.paragraph-text {
    display: flex;
    align-items: center;
    text-align: center;
    opacity: 0.5;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin: 40px;
}

.spinner {
    justify-items: center;
    margin: 20px;
    color: #8E9091;
}

.spinner-page {
    position: fixed;
    height: 74px;
    width: 72px;
    top: 50%;
    left: 50%;
    margin-left: -36px;
    margin-top: -37px;
}

.spinner-in-button {
    justify-items: center;
    color: #FFFFFF;
}

.action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border-width: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    min-height: 56px;
    max-height: 56px;
    color: white;
    flex: 1;
}

.single-action-button {
    @extend .action-button;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
}

.paired-action-button {
    @extend .action-button;
    margin-bottom: 0px;
    margin-left: 7.5px;
    margin-right: 7.5px;
    width: calc(100% - 15px);
}

.primary-button {
    @extend .single-action-button;
    background: #43BDF1;
    box-shadow: 0px 8px 12px rgba(67, 189, 241, 0.5);
}

  .secondary-button {
    @extend .single-action-button;
    background: #42B398;
    box-shadow: 0px 8px 12px rgba(66, 179, 152, 0.5);
}

.disabled-button {
    @extend .single-action-button;
    background: #8E9091;
}

.primary-button-paired {
    @extend .paired-action-button;
    background: #43BDF1;
    box-shadow: 0px 8px 12px rgba(67, 189, 241, 0.5);
}

  .secondary-button-paired {
    @extend .paired-action-button;
    background: #42B398;
    box-shadow: 0px 8px 12px rgba(66, 179, 152, 0.5);
}

.disabled-button-paired {
    @extend .paired-action-button;
    background: #8E9091;
}

.tertiary-button {
    @extend .single-action-button;
    background: transparent;
    color: black;
}

.choose-url-input-subtext {
    font-size: small;
    color: gray;
    margin-bottom: 0;
}

.choose-url-input-progress-bar-container {
    border: gray;
    border-radius: 5px;
    border-style: solid;
    width: 69%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 250px;
}

.choose-url-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 300px;
    margin: auto;

    div {
        width: 100%;
        
        input {
            width: 100%;
            margin: 0;
        }

        button {
            width: 100%;
            margin: 0;
            margin-bottom: 20px;
        }
    }
}

.choose-url-form-header {
    margin-bottom: 50px;
}

.css-79elbk {
    height: 100%
}

#hover-box {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

#hover-box::after {
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

#hover-box:hover {
    -webkit-transform: scale(1.25, 1.25);
    transform: scale(1.25, 1.25);
}

#hover-box:hover::after {
    opacity: 1;
}

.info-popup {
    background: #FFFFFF;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.3);
    margin: 20px;
    position: absolute;
    z-index: 10000;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%)
}



.info-popup-header {
    display: flex;
    justify-content: flex-end;
    margin: 8px;
}

.info-popup-close-button-img {
    height: 24px;
    width: 24px;
}

.info-popup-body {
    margin: 20px 30px 30px;
}

.sign-in-screen {
    width: 100%;
}