.landing-carousel {
  display: flex;
  flex-grow: 1;
  align-self: center;
  margin-top: 50px;
}

.landing-carousel-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-items: center;
  justify-content: space-evenly;
  padding: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

ol.carousel-indicators {
  position: absolute;
  bottom: 5px;
  margin: 0;
  left: 0;
  right: 0;
  width: auto;
}

ol.carousel-indicators li,
ol.carousel-indicators li.active {
  width: 8px;
  height: 8px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 50%;
  border: 0;
  background: transparent;
  background: black;
}

ol.carousel-indicators li {
  opacity: 0.4;
}

.sign-in-or-out {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: black;
  align-self: flex-end;
  margin: 25px;
  outline: none;
  border: 0;
  background-color: transparent;
}

.carousel-small-img {
  margin-bottom: 20px;
}
.carousel-large-img {
  margin-bottom: 40px;
}

.big-logo-with-logotext {
  margin-bottom: 0px;
}

.big-logo {
  margin-bottom: 40px;
}

.big-logotext {
  margin-bottom: 20px;
}