.vault-img {
  position: relative;
}

.profile-pic-blob-img {
  position: absolute;
  z-index: 1;
  left: 50%;
  width: 212px;
  height: 212px;
  margin: -106px;
}

.bank-search {
  align-items: center;
  margin: 30px 18px;
  width: calc(100% - 36px);
  border: 0;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: #979898;
}

.bank-search-input {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #7D7E7E;
  border: 0;
}

.bank-search-icon {
  opacity: 0.8;
  margin-left: 6px;
  margin-right: 15px
}

.bank-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 15px;
}

.bank-selection-card {
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border-width: 0px;
  height: 90px;
  margin-bottom: 12px;
  max-height: 100%;
  padding: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120.7%;
  align-items: center;
  color: black;
  width: 100%;
}

.bank-logo-img {
  display: flex;
  flex: 1;
  max-width: 25%;
  max-height: 100%;
}

.bank-name {
  display: flex;
  flex: 1;
  margin-left: 25px;
}

.profile-img-preview-container {
  position: absolute;
  display: flex;
  left: 50%;
  margin: -54px;
  width: 108px;
  height: 108px;
  align-items: center;
  z-index: 2;
}

.profile-pic-input {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	z-index: -1;
}

.profile-pic-input-edit {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;
  bottom: -66px;
}

.account-created-page-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 30px;
  flex: 1;
}

.choose-url-main-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: stretch;
  flex-grow: 1;
  justify-content: space-between;
}

.choose-url-body-text {
  max-width: 262px;
  margin: 20px;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
}

.choose-url-inputs {
  align-self: center;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #808080;
}

.choose-url-label {
  display: flex;
  border-width: 0px;
  text-align: left;
  align-items: center;
  width: fit-content;
  border-image: linear-gradient(200.95deg, #61E1A4 0.2%, #2DCCED 100%);
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-image-slice: 1;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.choose-url-link-img {
  width: 24px;
  height: 24px;
  align-self: center;
  margin: 7px
}

.choose-url-input {
  padding: 0;
  width: 100%;
  color: #000000
}

.choose-url-validity-icon {
  width: 24px;
  height: 24px;
  margin: 6px 11px;
}
