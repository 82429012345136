.enter-amount-page-container {
  background: linear-gradient(171.17deg, #FCFEFF 34.4%, #EFF1F2 72.71%);;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.enter-amount-header-container {
  display: flex;
  margin-top: 15px;
  margin-bottom: 30px;
  margin-right: 10px;
}

.enter-amount-cancel-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding: 11px 16px;
}

.enter-amount-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
}

.enter-amount-main-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: stretch;
  flex-grow: 1;
  justify-content: space-between;
}

.enter-amount-label {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  justify-content: left;
  font-style: normal;
  text-align: left;
  padding-right: 15px;
  padding-left: 15px;
  transition: all 0.5s
}

.enter-amount-label-previous {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: normal;
  transform: translate(0, -80px);
}

.enter-amount-label-more-previous {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: normal;
  transform: translate(0, -160px);
}

.enter-amount-label-inactive {
  opacity: 0;
  pointer-events: none;
  transform: translate(0, 200px);
}

.enter-amount-amount-label-active {
  @extend .enter-amount-label;
  font-weight: 900;
  font-size: 48px;
  line-height: 60px;
}

.enter-amount-amount-label-previous{
  @extend .enter-amount-label;
  @extend .enter-amount-label-previous;
}

.enter-amount-amount-label-more-previous {
  @extend .enter-amount-label;
  @extend .enter-amount-label-more-previous;
}

.enter-amount-amount-label-inactive {
  @extend .enter-amount-label;
  @extend .enter-amount-label-inactive;
}

.enter-amount-message-label-active {
  @extend .enter-amount-label;
  font-weight: 900;
  font-size: 48px;
  line-height: 60px;
}

.enter-amount-message-label-previous{
  @extend .enter-amount-label;
  @extend .enter-amount-label-previous;
}

.enter-amount-message-label-more-previous {
  @extend .enter-amount-label;
  @extend .enter-amount-label-more-previous;
}

.enter-amount-message-label-inactive {
  @extend .enter-amount-label;
  @extend .enter-amount-label-inactive;
}

.enter-amount-name-label-active {
  @extend .enter-amount-label;
  font-weight: 900;
  font-size: 48px;
  line-height: 60px;
}

.enter-amount-name-label-previous{
  @extend .enter-amount-label;
  @extend .enter-amount-label-previous;
}

.enter-amount-name-label-more-previous {
  @extend .enter-amount-label;
  @extend .enter-amount-label-more-previous;
}

.enter-amount-name-label-inactive {
  @extend .enter-amount-label;
  @extend .enter-amount-label-inactive;
}

.enter-amount-amount-label-header-active {
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  margin: 0px 50px;
}

.enter-amount-message-label-header-active,
.enter-amount-name-label-header-active {
  opacity: 0%;
}

.enter-amount-amount-label-header-previous,
.enter-amount-amount-label-header-more-previous,
.enter-amount-message-label-header-previous,
.enter-amount-message-label-header-more-previous,
.enter-amount-name-label-header-previous,
.enter-amount-name-label-header-more-previous {
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  opacity: 0.5;
  margin: 0px 30px;
}


.enter-amount-amount-img-active {
  width: 40px;
  height: 40px;
  margin: 0px 8px 0px 0px;
  align-self: center;
}
.enter-amount-amount-img-previous,
.enter-amount-amount-img-more-previous {
  width: 24px;
  height: 24px;
  margin: 0px 8px 0px 0px;
  align-self: center;
}

.enter-amount-message-img {
  width: 24px;
  height: 24px;
  align-self: center;
  margin: 7px
}
.enter-amount-message-img-active,
.enter-amount-name-img-active {
  @extend .enter-amount-message-img;
}
.enter-amount-message-img-previous,
.enter-amount-message-img-more-previous {
  @extend .enter-amount-message-img;
  opacity: 80%;
}

.enter-amount-inputs {
  width: fit-content;
  align-self: flex-start;
  margin: 20px;
}

.enter-amount-amount,
.enter-amount-message,
.enter-amount-name {
  display: flex;
  border-width: 0px;
  text-align: left;
  align-items: center;
}

.enter-amount-active {
  width: fit-content;
  border-image: linear-gradient(200.95deg, #61E1A4 0.2%, #2DCCED 100%);
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-image-slice: 1;
}

.enter-amount-amount-active {
  @extend .enter-amount-amount;
  @extend .enter-amount-active;
}

.enter-amount-amount-previous,
.enter-amount-amount-more-previous,
.enter-amount-amount-inactive {
  @extend .enter-amount-amount;
}

.enter-amount-message-active,
.enter-amount-name-active {
  @extend .enter-amount-message;
  @extend .enter-amount-active;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.enter-amount-message-previous,
.enter-amount-message-more-previous,
.enter-amount-name-previous,
.enter-amount-name-more-previous {
  @extend .enter-amount-message;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: normal;
  color: #333333;
}

.enter-amount-request-img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.enter-amount-amount-label-footer-active {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #010C0D;
  opacity: 0.7;
}

.enter-amount-amount-label-footer-previous,
.enter-amount-amount-label-footer-more-previous {
  opacity: 0;
}

.enter-amount-check-mark-img {
  width: 13px;
  height: 13px;
}

.enter-amount-bank-provider-logo {
  height: 20px;
  margin: 5px;
}

.enter-name-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.enter-name-body {
  align-self: flex-start;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
}

.enter-name-inputs {
  align-self: center;
  margin: 20px;
}

.enter-amount-primary-button {
  @extend .primary-button;
  z-index: 1;
  position: relative;
}

.enter-amount-secondary-button {
  @extend .secondary-button;
  z-index: 1;
  position: relative;
}

.enter-amount-disabled-button {
  @extend .disabled-button;
  z-index: 1;
  position: relative;
}

.enter-amount-amount-input,
.enter-amount-message-input,
.enter-amount-name-input {
  padding: 0;
}