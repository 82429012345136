.help-page {
  justify-content: flex-start;
  text-align: left;
}

.help-header {
  text-align: center;
  margin: 30px;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
  padding: none;

}

.help-home-button {
  position: absolute;
  top: 23px;
  left: 14px;
  height: 40px;
  width: 40px;
  padding: 0;
  margin: 0;
}

.help-home-button-logo {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.help-header-spacer {
  width: 40px;
}

.help-body {
  margin: 50px 25px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #010C0D;
}

input[type='checkbox'] {
  display: none;
}

.help-collapsible-label-toggle {
  display: block;
  font-weight: 600;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.25s ease-out;
}

.help-collapsible-label-toggle::before {
  content: ' ';
  display: inline-block;

  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;

  vertical-align: middle;
  margin-right: .7rem;
  transform: translateY(-2px);

  transition: transform .2s ease-out;
}

.help-collapsible-content{
  padding: 0 14px
}
.help-collapsible-content {
  max-height: 0px;
  overflow: hidden;

  transition: max-height .25s ease-in-out;
}

.help-collapsible-toggle:checked + .help-collapsible-label-toggle + .help-collapsible-content {
  max-height: 100vh;
}

.help-collapsible-toggle:checked + .help-collapsible-label-toggle::before {
  transform: rotate(90deg) translateX(-3px);
}

.help-collapsible-toggle:checked + .help-collapsible-label-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}